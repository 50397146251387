<template>
  <div :class="!edit ? 'card-commu' : 'card-commu-edit'">
    <div class="my-2 font-w-600">{{ title }}</div>
    <span v-if="$route.name != 'Automation Detail'">
      Send Customers : {{ estimatedCustomer.userLine | numeral("0,0") }}</span
    ><span
      v-if="
        estimatedCustomer.userSend > 0 && $route.name != 'Automation Detail'
      "
      >/{{ estimatedCustomer.userSend | numeral("0,0") }}</span
    >
    <div class="container-card b-white" v-if="!edit">
      <div class="vertical-center">
        <div>No Items</div>
      </div>
    </div>
    <template v-else>
      <div class="container-card scroll-content">
        <div v-for="(item, index) in value" :key="index">
          <div v-if="item.communication_type_id === 3">
            <div v-for="(line, i) in item.detail_list" :key="i">
              <div
                v-for="(list, i) in line.communication_value"
                :key="i"
                class="p-3"
              >
                <div
                  v-if="list.value || list.image_url"
                  class="container-card-list mb-2"
                >
                  <b-row
                    v-if="
                      parseInt(line.message_type_id) === 8 ||
                      parseInt(line.message_type_id) === 9
                    "
                    cols="6"
                    cols-lg="4"
                  >
                    <b-col class="mt-2 text-center px-0">
                      <img class="crop-img" :src="list.image_url" />
                    </b-col>
                  </b-row>

                  <div v-else>
                    <!-- <div v-for="(list,i) in line.communication_value" :key="i"> -->
                    <div
                      v-if="
                        parseInt(line.message_type_id) === 12 ||
                        parseInt(line.message_type_id) === 7
                      "
                      class="mt-2"
                    >
                      <img class="crop-img" :src="list.image_url" />
                    </div>
                    <div
                      v-if="parseInt(line.message_type_id) === 9"
                      class="mt-2 font-w-600"
                    >
                      IMAGE MAP
                    </div>
                    <div v-if="list.value">{{ list.value }}</div>
                    <!-- </div> -->
                  </div>

                  <div v-if="line.format_list">
                    <div
                      class="bg-button mt-2"
                      v-for="(format, j) in line.format_list"
                      :key="j"
                    >
                      <font-awesome-icon icon="circle" />
                      {{ format.option_name }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    line.message_type_id == 14 || line.message_type_id == 13
                  "
                >
                  <div
                    class="card"
                    v-for="(formatList, n) of line.format_list"
                    :key="'image-' + n"
                  >
                    <template v-if="formatList.format_id == 3">
                      <div class="p-3 text-center">
                        {{ formatList.closing_text }}
                      </div>
                    </template>
                    <template v-else>
                      <div v-if="formatList.number_of_images == 2">
                        <b-row
                          class="no-gutters"
                          :set="
                            (imageList = formatList.details.filter(
                              (el) => el.format_detail_type == 2
                            ))
                          "
                          ><b-col
                            sm="6"
                            v-for="(image, n) of imageList"
                            :key="'banner-image' + n"
                          >
                            <b-img
                              :src="image.image"
                              class="w-100 h-100"
                            ></b-img
                          ></b-col>
                        </b-row>
                      </div>
                      <div v-else-if="formatList.number_of_images == 3">
                        <b-row
                          class="no-gutters"
                          :set="
                            (imageList = formatList.details.filter(
                              (el) => el.format_detail_type == 2
                            ))
                          "
                          ><b-col sm="6">
                            <b-img
                              :src="imageList[0].image"
                              class="w-100 h-100"
                            ></b-img
                          ></b-col>
                          <b-col sm="6">
                            <b-img
                              :src="imageList[1].image"
                              class="w-100 h-50"
                            ></b-img>
                            <b-img
                              :src="imageList[2].image"
                              class="w-100 h-50"
                            ></b-img
                          ></b-col>
                          <b-col sm="6"> </b-col>
                        </b-row>
                      </div>
                      <div v-else>
                        <b-img
                          :src="
                            formatList.details.find(
                              (el) => el.format_detail_type == 2
                            ).image
                          "
                          class="w-100"
                        ></b-img>
                      </div>
                      <div class="p-3">
                        <div v-if="formatList.header_name">
                          {{ formatList.header_name }}
                        </div>
                        <div v-if="formatList.is_descriptions == 1">
                          {{ formatList.description }}
                        </div>
                        <div class="text-right" v-if="formatList.is_price == 1">
                          {{ formatList.price_currency
                          }}{{ formatList.price | numeral("0,0.00") }}
                        </div>
                        <div class="text-center">
                          <u> {{ formatList.details[0].action_title_name }}</u>
                        </div>
                      </div>

                      <div
                        v-if="formatList.is_banner"
                        class="badge banner-badge"
                        :style="{
                          'background-color': `${formatList.color}`,
                          color: `${
                            formatList.color == 'black' ? 'white' : 'black'
                          }`,
                        }"
                      >
                        {{ formatList.banner }}
                      </div>
                    </template>
                  </div>
                </div>
                <div v-if="line.message_type_id == 15">
                  <div
                    class="card"
                    v-for="(formatList, n) of line.format_list"
                    :key="'image-' + n"
                  >
                    <b-img
                      :src="
                        formatList.details.find(
                          (el) => el.format_detail_type == 2
                        ).image
                      "
                      class="w-100"
                    ></b-img>
                  </div>
                </div>
                <div class="my-3 text-edit-communication" v-if="!disableStatus">
                  <button
                    class="btn-edit mr-2"
                    @click.prevent="openSidebar"
                    :disabled="disableStatus"
                  >
                    <font-awesome-icon icon="pencil-alt" class="mr-2" />
                    <span class="text-underline">Edit</span>
                  </button>
                  <button
                    class="btn-edit mr-2"
                    v-if="item.detail_list.length === 1"
                    @click.prevent="deleteLine"
                    :disabled="disableStatus"
                  >
                    <font-awesome-icon icon="trash-alt" class="mr-2" />
                    <span class="text-underline">Delete</span>
                  </button>
                </div>
              </div>
            </div>
            <button class="btn-edit mr-2" @click.prevent="modalTestSend">
              <font-awesome-icon icon="paper-plane" class="mr-2" />
              <span class="text-underline">Test Send</span>
            </button>
          </div>
        </div>
      </div>
    </template>

    <div class="mt-3" v-if="!disableStatus">
      <button
        class="btn-add"
        @click.prevent="openSidebar"
        :disabled="disableStatus"
      >
        <font-awesome-icon icon="plus-square" class="mr-2" />
        <span class="add-new">Create a New List</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    edit: {
      required: true,
      type: Boolean,
    },
    value: {
      required: true,
      type: Array,
    },
    disableStatus: {
      required: false,
      type: Boolean,
    },
    idCommu: {
      required: true,
      type: Number,
    },
    estimatedCustomer: {
      required: false,
    },
  },
  methods: {
    openSidebar() {
      this.$emit("sideBarOpen");
    },
    deleteLine() {
      this.$emit("deleteMessage", 3);
    },
    modalTestSend() {
      this.$emit("modalTestSend", this.idCommu);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-commu {
  background-color: #f5f5f5;
  padding: 5px 15px;
}
.card-commu-edit {
  background-color: #f5f5f5;
  padding: 5px 15px;
}
.font-w-600 {
  font-weight: 600;
}
.container-card {
  min-height: 250px;
  height: 150px;
  position: relative;
}
.container-card-list {
  background-color: #fff;
  padding: 10px;
  width: 100%;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.text-underline {
  text-decoration: underline;
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.btn-edit {
  background-color: transparent;
  border: none;
  color: gray;
}
.scroll-content {
  overflow-y: scroll;
  overflow-x: hidden;
}
.bg-button {
  background-color: var(--secondary-color);
  color: #7160c4;
  border-color: var(--secondary-color);
  font-size: 14px;
  border-radius: 5px;
  padding: 10px;
}
::v-deep .svg-inline--fa.fa-w-16 {
  width: 0.75em;
}
.crop-img {
  position: relative;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 2px dashed #bebebe;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.b-white {
  background-color: #fff;
}

.d-column {
  display: flex;
  flex-direction: column;
}
.banner-badge {
  background-color: rgb(91, 130, 219);
  position: absolute;
  top: 3%;
  left: 3%;
  color: white;
  padding: 0.35rem;
  letter-spacing: 0.1rem;
}
</style>
