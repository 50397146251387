<template>
  <div>
    <b-row>
      <b-col cols="6">
        <ImageUpload
          class="mt-1"
          v-bind:dataFile="data.details[1].image"
          :index="0"
          :imageIndex="0"
          imageRatio="1.11/1"
          :withSize="true"
          @handleChangeImage="handleChangeImage"
          desc="(Recommend upload file .png .jpg and less than 10 MB)"
          :isValid="$v.data.details.$each.$iter[1].image.$error"
          :v="$v.data.details.$each.$iter[1].image"
        />
      </b-col>

      <div class="break-normal"></div>
      <b-col cols="6">
        <b-form-checkbox
          :id="'is_action' + formIndex"
          class="mb-3"
          v-model="data.details[0].is_action"
          @change="handleAction($event, data.details[0])"
          :name="'is_action' + formIndex"
          :value="1"
          :unchecked-value="0"
        >
          Action
        </b-form-checkbox>
      </b-col>
      <div class="break-normal"></div>
      <b-col cols="6">
        <InputText
          textFloat=""
          placeholder="Action Name 15 Characters"
          type="text"
          maxLength="15"
          :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 15);'"
          v-model="data.details[0].action_title_name"
          :name="'action_title_name' + formIndex"
          :disabled="data.details[0].is_action == 0"
          :v="$v.data.details.$each.$iter[0].action_title_name"
          :isValidate="$v.data.details.$each.$iter[0].action_title_name.$error"
      /></b-col>

      <div class="break-normal"></div>
      <b-col cols="6">
        <InputSelect
          title=""
          :name="'card-message-type' + formIndex"
          isRequired
          :options="actionType"
          v-model="data.details[0].line_action_type_id"
          valueField="id"
          textField="name"
          :disabled="data.details[0].is_action == 0"
          :v="$v.data.details.$each.$iter[0].line_action_type_id"
          :isValidate="
            $v.data.details.$each.$iter[0].line_action_type_id.$error
          "
        >
          <template v-slot:option-first>
            <b-form-select-option value="0" disabled
              >Action Type
            </b-form-select-option>
          </template>
        </InputSelect>
      </b-col>
      <b-col cols="6">
        <InputText
          textFloat=""
          :placeholder="
            data.details[0].line_action_type_id == 1
              ? 'Text 30 Characters'
              : 'Please Enter URL'
          "
          type="text"
          v-model="data.details[0].action_text"
          :name="'action_text' + formIndex"
          maxLength="30"
          :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 30);'"
          :disabled="
            data.details[0].is_action == 0 ||
            data.details[0].line_action_type_id == '0'
          "
          :v="$v.data.details.$each.$iter[0].action_text"
          :isValidate="$v.data.details.$each.$iter[0].action_text.$error"
      /></b-col>
    </b-row>
  </div>
</template>

<script>
import { required, requiredIf, url } from "vuelidate/lib/validators";
import ImageUpload from "@/components/automation/detail/LINE/TypeMessage/ImageUpload";
const detail = [
  {
    format_detail_type: 1,
    is_action: 0,
    line_action_type_id: "0",
    action_title_name: "",
    action_text: "",
    image: "",
    image_width: "",
    image_height: "",
  },
  {
    format_detail_type: 2,

    is_action: 0,
    line_action_type_id: "0",
    action_title_name: "",
    action_text: "",
    image: "",
    image_width: "",
    image_height: "",
  },
];
const messageData = {
  format_id: 15, // type มี id 2 image, 3 text , 4 image text 5 product message
  sort_order: 1,
  details: [
    // detail จะมีก็ต่อเมื่อเป็น card message หรือ product message
    ...detail,
  ],
};
function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
export default {
  components: {
    ImageUpload,
  },
  props: {
    response: {
      required: true,
      type: Object,
    },
    v: {
      required: false,
      type: Object,
    },
    text: {
      required: true,
      type: Object,
    },
    formIndex: {
      required: false,
    },
  },
  validations() {
    return {
      data: {
        details: {
          $each: {
            action_title_name: {
              required: requiredIf(function (item, data) {
                return item.format_detail_type == 1 && item.is_action == 1;
              }),
            },
            line_action_type_id: {
              required: function (item, value) {
                return item == "0" &&
                  value.format_detail_type == 1 &&
                  value.is_action == 1
                  ? false
                  : true;
              },
            },
            image: {
              required: requiredIf(function (item, data) {
                return item.format_detail_type == 2;
              }),
            },
            action_text: {
              required: requiredIf(function (item) {
                return item.is_action;
              }),
              url: function (item, data) {
                return data.line_action_type_id == 2 && data.is_action == 1
                  ? !validURL(item)
                    ? false
                    : true
                  : true;
              },
            },
          },
        },
      },
    };
  },
  created() {
    this.inilizeValue();
  },
  data() {
    return {
      data: [],

      actionType: [
        { id: 1, name: "Text" },
        { id: 2, name: "URL" },
      ],
    };
  },
  methods: {
    handleChangeImage(image, index, imageIndex, { width, height }) {
      this.data.details[1].image = image;
      this.data.details[1].image_width = width;
      this.data.details[1].image_height = height;
    },

    onSubmit() {
      this.$v.data.$touch();

      if (this.$v.data.$error) {
        return false;
      }
      this.response.format_list = [this.data];

      return true;
    },
    handleAction(val, obj) {
      if (!val) {
        obj.action_text = "";
        obj.action_title_name = "";
        obj.line_action_type_id = "0";
      }
    },

    handleDelete(index) {
      this.data.splice(index, 1);
    },

    clearValue(type) {
      if (JSON.parse(JSON.stringify(this.response.format_list)).length < 1) {
        this.data = [JSON.parse(JSON.stringify(messageData))];
      } else {
        this.data = JSON.parse(JSON.stringify(this.response.format_list));
      }
    },
    inilizeValue(type) {
      console.log(this.response.format_list);
      if (this.response.format_list.length > 0) {
        var details = JSON.parse(JSON.stringify(messageData.details));

        const action = this.response.format_list[0].details.filter(
          (el) => el.format_detail_type == 1
        );
        const image = this.response.format_list[0].details.filter(
          (el) => el.format_detail_type == 2
        );

        if (action.length > 0) {
          details[0] = { ...action[0] };
        }
        if (image.length > 0) {
          details[1] = { ...image[0] };
        }

        this.response.format_list[0].details = JSON.parse(
          JSON.stringify(details)
        );

        this.data = JSON.parse(JSON.stringify(this.response.format_list[0]));

        // this.data.details = JSON.parse(JSON.stringify(this.response.format_list.details.length));
      }

      if (this.data.length == 0) {
        this.data = JSON.parse(JSON.stringify(messageData));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picker-color {
  width: 25px;
  height: 25px;
  background-color: var(--color);
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;
}
.icon-check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--check-color);
}
.isValid {
  border: 1px solid red;
  padding: 0.25rem;
  width: max-content;
  border-radius: 0.25rem;
}
</style>
