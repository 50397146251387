<template>
  <div>
    <b-sidebar
      id="sidebar-line"
      ref="sidebarLine"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-line-header"
      right
      backdrop
      shadow
      no-header
      width="80vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter" cols="6">Line</b-col>
          <b-col class="text-right" cols="6">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <InputText
              v-if="form[form.findIndex((el) => el.communication_type_id == 3)]"
              textFloat="Preview Text"
              placeholder="Preview Text"
              type="text"
              name="preview-text"
              v-model="
                form[form.findIndex((el) => el.communication_type_id == 3)]
                  .line_preview_text
              "
            /> </b-col
        ></b-row>

        <LineMessageTypeComponent
          ref="LineMessage"
          :messageType="messageType"
          :list="form"
          :formMain="formMain"
          :v="v"
          :isShowSidebar="isShowSidebar"
          :dataImage="dataImage"
          :id="id"
          :variableItem="
            typeLine === 'segment' && formMain.segmentationType === 2
              ? variableItemSegment
              : variableItem
          "
        />
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              class="text-body rounded-pill btn-main"
              @click.prevent="submitLine"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import LineMessageTypeComponent from "@/components/automation/detail/LINE/LineMessageTypeComponent";
const variableList = [
  "invoiceNo",
  "orderDateYear",
  "orderDateMonth",
  "orderDateDay",
  "branchNameTH",
  "branchNameEN",
  "salesNo",
  "salesFirstnameTH",
  "salesLastnameTH",
  "salesFirstnameEN",
  "salesLastnameEN",
  "transactionCentralPoint",
  "transactionBranchPoint",
  "transactionBranchPointBalance",
];
const variableRedeemList = [
  "redeemPoint",
  "redeemItemName",
  "redeemBranchName",
  "transactionBranchPointBalance",
];
export default {
  components: {
    LineMessageTypeComponent,
  },
  props: {
    form: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    id: {
      required: false,
      type: String,
    },
    dataImage: {
      required: false,
      type: Array,
    },
    messageType: {
      required: true,
      type: Array,
    },
    typeLine: {
      required: true,
      type: String,
    },
    formMain: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      variableItem: [
        { id: 1, name: "firstname" },
        { id: 2, name: "lastname" },
        { id: 3, name: "point" },
        { id: 4, name: "telephone" },
        { id: 5, name: "memberId" },
      ],
      redeemVariableItem: [],
      variableItemSegment: [
        { id: 1, name: "firstname" },
        { id: 2, name: "lastname" },
        { id: 3, name: "point" },
        { id: 4, name: "url_web" },
        { id: 5, name: "url_line" },
        { id: 6, name: "memberId" },
      ],
    };
  },
  methods: {
    show() {
      this.variableItem = [
        { id: 1, name: "firstname" },
        { id: 2, name: "lastname" },
        { id: 3, name: "point" },
        { id: 4, name: "telephone" },
        { id: 5, name: "memberId" },
      ];
      if (this.formMain.event_type_id == 6) {
        var i = 6;
        for (const list of variableList) {
          this.variableItem.push({ id: i, name: list });
        }
      } else if (this.formMain.event_type_id == 12) {
        var i = 14;
        for (const list of variableRedeemList) {
          this.variableItem.push({ id: i, name: list });
        }
      }

      this.isShowSidebar = true;
    },
    hide() {
      for (const form of this.form) {
        if (form.communication_type_id == 3) {
          for (let index = 0; index < form.detail_list.length; index++) {
            if (
              this.$refs.LineMessage.$refs.reponseForm[index].$refs
                .productTypeMessage
            ) {
              this.$refs.LineMessage.$refs.reponseForm[
                index
              ].$refs.productTypeMessage[0].clearValue(1);
            } else if (
              this.$refs.LineMessage.$refs.reponseForm[index].$refs
                .cardTypeMessage
            ) {
              this.$refs.LineMessage.$refs.reponseForm[
                index
              ].$refs.cardTypeMessage[0].clearValue(1);
            } else if (
              this.$refs.LineMessage.$refs.reponseForm[index].$refs.clickAble
            ) {
              this.$refs.LineMessage.$refs.reponseForm[
                index
              ].$refs.clickAble[0].clearValue(1);
            }
          }
        }
      }
      this.isShowSidebar = false;
    },
    submitLine() {
      let error = false;
      for (const form of this.form) {
        if (form.communication_type_id == 3) {
          for (let index = 0; index < form.detail_list.length; index++) {
            if (
              this.$refs.LineMessage.$refs.reponseForm[index].$refs
                .productTypeMessage
            ) {
              let check =
                this.$refs.LineMessage.$refs.reponseForm[
                  index
                ].$refs.productTypeMessage[0].onSubmit();
              if (!check) {
                error = true;
              }
            } else if (
              this.$refs.LineMessage.$refs.reponseForm[index].$refs
                .cardTypeMessage
            ) {
              let check =
                this.$refs.LineMessage.$refs.reponseForm[
                  index
                ].$refs.cardTypeMessage[0].onSubmit();
              if (!check) {
                error = true;
              }
            } else if (
              this.$refs.LineMessage.$refs.reponseForm[index].$refs.clickAble
            ) {
              let check =
                this.$refs.LineMessage.$refs.reponseForm[
                  index
                ].$refs.clickAble[0].onSubmit();
              if (!check) {
                error = true;
              }
            }
          }
        }
      }

      this.v.$touch();
      if (this.v.$error || error) {
        return;
      }

      this.$emit("settingLine", 3);
      this.isShowSidebar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.title-msg {
  color: #000;
  font-size: 17px;
}
.btn-outline {
  background-color: var(--secondary-color);
  border-radius: 50px;
  color: var(--primary-color);
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
</style>
