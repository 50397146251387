<template>
  <div class="card-commu">
    <div class="my-2 font-w-600">{{ title }}</div>

    <span v-if="$route.name != 'Automation Detail'">
      Send Customers :
      {{
        idCommu === 2
          ? estimatedCustomer.userTelephone
          : idCommu === 1
          ? estimatedCustomer.userEmail
          : "" | numeral("0,0")
      }}</span
    >
    <span
      v-if="
        estimatedCustomer.userSend > 0 && $route.name != 'Automation Detail'
      "
      >/{{ estimatedCustomer.userSend | numeral("0,0") }}</span
    >
    <div class="container-card" v-if="!edit">
      <div class="vertical-center">
        <div>No Items</div>
      </div>
    </div>
    <template v-else>
      <div v-if="idCommu === 2">
        <div v-for="(item, index) in value" :key="index">
          <div
            v-if="item.communication_type_id === 2"
            class="container-card scroll-content p-3"
          >
            <div v-for="(list, i) in item.detail_list" :key="i">
              <div v-if="item.communication_type_id === 2">
                {{ list.communication_value[0].value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="idCommu === 1">
        <!-- Send Customers : {{ estimatedCustomer.userEmail | numeral("0,0") }} -->
        <div v-for="(item, index) in value" :key="index">
          <div
            class="container-card scroll-content p-3"
            v-if="item.communication_type_id === 1"
          >
            <div v-for="(email, e) in item.detail_list" :key="e" class="zoom-preview-email">
              <div
                v-if="item.communication_type_id === 1"
                v-html="email.communication_value[0].value"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="idCommu === 4">
        <div v-for="(item, index) in value" :key="index">
          <div
            v-if="item.communication_type_id === 4"
            class="container-card scroll-content p-3"
          >
            <div v-for="(list, i) in item.detail_list" :key="i">
              <div v-if="item.communication_type_id === 4">
                {{ list.communication_value[0].value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="my-3 text-edit-communication" v-if="!disableStatus && edit">
      <button
        class="btn-edit mr-2"
        @click.prevent="sideBarSMS"
        :disabled="disableStatus"
      >
        <font-awesome-icon icon="pencil-alt" class="mr-2" />
        <span class="text-underline">Edit</span>
      </button>
      <button
        class="btn-edit mr-2"
        @click.prevent="deleteMessage"
        :disabled="disableStatus"
      >
        <font-awesome-icon icon="trash-alt" class="mr-2" />
        <span class="text-underline">Delete</span>
      </button>
      <button class="btn-edit mr-2" @click.prevent="modalTestSend">
        <font-awesome-icon icon="paper-plane" class="mr-2" />
        <span class="text-underline">Test Send</span>
      </button>
    </div>
    <div class="mt-3" v-if="!disableStatus">
      <button
        class="btn-add"
        @click.prevent="sideBarSMS"
        :disabled="disableStatus"
      >
        <font-awesome-icon icon="plus-square" class="mr-2" />
        <span class="add-new">Create a New List</span>
      </button>
    </div>
    <ModalConfirm ref="confirmModal" :text="confirmMsg" @handler="deleteMsg" />
  </div>
</template>

<script>
import ModalConfirm from "@/components/modal/ModalConfirm";
export default {
  components: {
    ModalConfirm,
  },
  props: {
    edit: {
      required: true,
      type: Boolean,
    },
    value: {
      required: true,
      type: Array,
    },
    title: {
      required: true,
      type: String,
    },
    idCommu: {
      required: true,
      type: Number,
    },
    disableStatus: {
      required: false,
      type: Boolean,
    },
    estimatedCustomer: {
      required: false,
    },
  },
  data() {
    return {
      confirmMsg: "",
    };
  },
  methods: {
    sideBarSMS() {
      this.$emit("sideBarOpen");
    },
    deleteMessage() {
      this.confirmMsg = "Confirm Delete";
      this.$refs.confirmModal.show();
    },
    deleteMsg() {
      this.$emit("deleteMessage", this.idCommu);
    },
    modalTestSend() {
      this.$emit("modalTestSend", this.idCommu);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-commu {
  background-color: #f5f5f5;
  padding: 5px 15px;
}
.font-w-600 {
  font-weight: 600;
}
.container-card {
  min-height: 250px;
  height: 150px;
  position: relative;
  background-color: #fff;
}
.zoom-preview-email {
  zoom: 0.25;    
  -moz-transform: scale(1);    
  -moz-transform-origin: 0 0;    
  -o-transform: scale(1);    
  -o-transform-origin: 0 0;    
  -webkit-transform: scale(1);    
  -webkit-transform-origin: 0 0;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.text-underline {
  text-decoration: underline;
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
::v-deep img {
  width: 100% !important;
}
.scroll-content {
  overflow-y: scroll;
}
.btn-edit {
  background-color: transparent;
  border: none;
  color: gray;
}
</style>
