<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div class="p-0 b-color-white">
      <div class="b-tranparent-purple p-3">
        <HeaderReport
          :all="headerData.send_all"
          :success="headerData.send_success"
          :fail="headerData.send_fail"
          :percen="headerData.send_success_percen"
        />
      </div>
      <b-row class="p-3">
        <b-col class="text-left" md="8" lg="6" xl="4">
          <b-input-group>
            <b-form-input
              class="search-bar"
              @keydown.enter.prevent="handleSearch"
              placeholder="Search ID, Name"
              v-model="search"
            >
            </b-form-input>
            <b-input-group-append is-text>
              <b-iconstack
                font-scale="2"
                type="submit"
                @click.prevent="handleSearch"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="text-right" md="4" lg="6" xl="8">
          <button
            type="button"
            class="btn button btn-mobile ml-2"
            @click.prevent="exportExcel"
            :disabled="disableBtnExport"
          >
            <font-awesome-icon icon="filter" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="file-export" class="pointer" />
              Export
            </span>
          </button>
        </b-col>
      </b-row>
      <TabListOverview
        :filter="filter"
        :userData="userData"
        :fields="fields"
        :isBusy="isBusy"
        :showingTo="showingTo"
        :rows="rows"
        @chkTabIndex="handleTabsChange"
        @changePage="handleChangePage"
        @getDataInPagination="getDataInPagination"
      />
      <SidebarFilter ref="sidebarFilter" />
      <ModalInputEmail
        ref="ModalInputEmail"
        :form="formExport"
        @closeModal="clearExportFilter"
        @changeEmail="(val) => (formExport.email = val)"
        @submit="exportExcel"
      />
    </div>
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderReport from "@/components/automation/segmentation/HeaderReport";
import TabListOverview from "@/components/automation/segmentation/TabListOverview";
import SidebarFilter from "@/components/automation/segmentation/SidebarFilter";
export default {
  components: {
    OtherLoading,
    HeaderReport,
    TabListOverview,
    SidebarFilter,
    ModalInputEmail,
  },
  props: {
    userData: {
      required: true,
      type: Object,
    },
    fields: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
    showingTo: {
      required: false,
      type: Number,
    },
    rows: {
      required: false,
      type: Number,
    },
    type: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    headerData: {
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      chkTab: 0,
      search: "",
      disableBtnExport: false,
      formExport: {
        email: "",
        segmentation_id: this.id,
      },
    };
  },
  methods: {
    handleSearch() {
      this.filter.search = this.search;

      this.$emit("searchUserRegister");
    },
    handleChangePage() {
      this.$emit("searchUserRegister");
    },
    handleTabsChange(val) {
      this.filter.channel = val;
      this.$emit("searchUserRegister");
    },
    async exportExcel() {
      this.disableBtnExport = true;
      this.$bus.$emit("showLoading");
      if (this.type === "automation") {
        await this.$store.dispatch("exportAutomationUser", this.id);
        let data = this.$store.state.automation.respExportAutomation;
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `MessageLog-` + dateExcel + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.disableBtnExport = false;
      } else {
        await this.$store.dispatch("exportSegmentationUser", this.formExport);
        this.successAlert(
          "The file has been sent to email. (If not received, please wait 5-10 minutes.)"
        );
      }
      this.$EventBus.$emit("hideLoading");
    },
    toggleModal() {
      this.$refs.ModalInputEmail.show();
    },
    getDataInPagination(value) {
      this.$emit("getDataInPagination", value);
    },
    clearExportFilter() {
      this.formExport = {
        email: "",
        segmentation_id: this.id,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.b-tranparent-purple {
  background-color: var(--secondary-color);
}
.b-color-white {
  background-color: #fff;
}
</style>
